import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import groupBy from 'lodash-es/groupBy';
import keyBy from 'lodash-es/keyBy';
import mapValues from 'lodash-es/mapValues';
import reduce from 'lodash-es/reduce';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import u from 'updeep';

import { dayList, format, getDayCode } from '../../../shared/date.helper';
import { getPermissionState, hasPermission, PermissionCheck } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { getContractTypeEntities } from '../contract-type/contract-type.service';
import { DepartmentModel } from '../department/department.model';
import { getDepartmentEntities } from '../department/department.service';
import { getEmployeeEntities } from '../employee/employee.service';
import { LocationModel } from '../location/location.model';
import { getLocationEntities } from '../location/location.service';
import { hasPermissionForAllDepartments } from './../../auth/permission.helper';
import { getSelectedDepartmentIds } from './../../selected-departments/selected-departments.service';
import { ContractAction } from './contract.action';
import { ContractApi, ContractAverageDailyHoursRequest, ContractsLoadRequest } from './contract.api';
import {
  ContractInfo,
  ContractInfoPerDay,
  ContractInfoPerEmployeePerDay,
  ContractModel,
  ContractState,
} from './contract.model';

@Injectable()
export class ContractService {
  public constructor(
    private store: Store<AppState>,
    private api: ContractApi,
  ) {}

  public load(requestData: ContractsLoadRequest, updateStore = true) {
    const check: PermissionCheck = {
      permissions: ['View contracts', 'View salary'],
      userId: 'me',
      departments: 'any',
    };

    return this.store.select(getPermissionState).pipe(
      map((permissionState) => hasPermission(check, permissionState)),
      switchMap((hasPerm: boolean) => {
        if (hasPerm) {
          return this._load(requestData, updateStore);
        }

        return observableOf(undefined);
      }),
      first(),
    );
  }

  private _load(requestData: ContractsLoadRequest, updateStore) {
    return this.api.load(requestData, ContractAction.load(requestData)).pipe(
      map((response) => {
        if (updateStore) {
          this.store.dispatch(ContractAction.loadSuccess(response));
        }

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(ContractAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(contractData): Observable<any> {
    return this.api.add(contractData, ContractAction.add(contractData)).pipe(
      map((response) => {
        this.store.dispatch(ContractAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(ContractAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, contractData) {
    return this.api.update(id, contractData, ContractAction.update(contractData)).pipe(
      map((response) => {
        this.store.dispatch(ContractAction.updateSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(ContractAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    return this.api.fetch(id, ContractAction.fetch(id)).pipe(
      map((response) => {
        this.store.dispatch(ContractAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(ContractAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(id) {
    this.store.dispatch(ContractAction.remove(id));

    return this.api.remove(id).pipe(
      map((response) => {
        this.store.dispatch(ContractAction.removeSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(ContractAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(contractData) {
    if (contractData.id) {
      return this.update(contractData.id, contractData);
    }

    contractData = u.omit('id', contractData);

    return this.add(contractData);
  }

  /**
   * Call contract bulk api.
   *
   * @param contractData
   * @returns {Observable<any>}
   */
  public bulkChange(contractData) {
    return this.api.bulkChange(contractData);
  }

  /**
   * Call change wage api.
   *
   * @param wageData
   * @returns {Observable<any>}
   */
  public changeSalary(wageData) {
    return this.api.changeWage(wageData);
  }

  public fetchContractAverageDailyHours(requestData: ContractAverageDailyHoursRequest) {
    return this.api.fetchContractAverageDailyHours(requestData);
  }
}

export const contractPeriodFilter =
  (minDate, maxDate) =>
  (contract: ContractModel): boolean =>
    maxDate >= contract.startdate && (!contract.enddate || minDate < contract.enddate);

const contractInfoPerDay = (contracts: ContractModel[], days: string[]): ContractInfoPerDay => {
  const contractsPerday = days.map((day) => {
    let contract: ContractModel;

    const emptyContract: ContractInfo = {
      date: day,
      hours: 0,
      wage: '0',
      coc: '0',
      contractTypeId: null,
      departmentId: null,
    };

    if (contracts.length === 0) {
      return emptyContract;
    } else if (contracts.length === 1) {
      contract = contracts[0];
    } else {
      contract = contracts.find(contractPeriodFilter(day, day));

      if (!contract) {
        return emptyContract;
      }
    }

    const dayCode = getDayCode(day);

    const contractHours = parseFloat(contract[dayCode]);

    const info: ContractInfo = {
      date: day,
      hours: contractHours,
      wage: contract.wage,
      coc: contract.coc,
      contractTypeId: contract.contract_type_id,
      departmentId: contract.department_id,
    };
    return info;
  });

  return keyBy(contractsPerday, 'date');
};

export const contractInfoPerEmployeePerDay = (
  minDate: string,
  maxDate: string,
  contracts: ContractModel[],
): ContractInfoPerEmployeePerDay => {
  const days = dayList(minDate, maxDate);

  const contractsInPeriod = contracts.filter(contractPeriodFilter(minDate, maxDate));
  const contractsPerEmployee = groupBy(contractsInPeriod, 'user_id');

  return mapValues(contractsPerEmployee, (employeeContracts) => contractInfoPerDay(employeeContracts, days));
};

export const sumContractInfo = (contractsPerDay: ContractInfoPerDay): number =>
  reduce(contractsPerDay, (acc, contractInfo) => acc + contractInfo.hours, 0);

export const sortContracts = (contracts: ContractModel[]) => sortBy(contracts, 'startdate');
export const mapAndSortContracts = mapAndSortEntities(sortContracts) as unknown as (
  ids: string[],
  contracts: Record<string, ContractModel>,
) => ContractModel[];

export const getContractState = (appState: AppState): ContractState => appState.orm.contracts;

export const getContractsAreLoading = createSelector(getContractState, (contractState) => contractState.loading);
export const getContractIds = compose((state: ContractState) => state.items, getContractState);

export const getContractEntities = createSelector(
  getContractTypeEntities,
  getEmployeeEntities,
  getContractState,
  getDepartmentEntities,
  getLocationEntities,
  (contractTypes, employees, state, departmentEntities, locationEntities) =>
    mapValues(state.itemsById, (contract) => {
      const departmentId = contract.department_id;
      const department = departmentEntities[departmentId] ? departmentEntities[departmentId] : ({} as DepartmentModel);
      const locationId = department.location_id;
      const location =
        locationId && !!locationEntities[locationId] ? locationEntities[locationId] : ({} as LocationModel);

      return {
        ...contract,
        department_name: department && department.name ? department.name : '',
        location_name: location && location.name ? location.name : '',
        contract_type: contractTypes[contract.contract_type_id],
        Employee: employees[contract.user_id],
      } as ContractModel;
    }),
);

export const getContracts = createSelector(getContractIds, getContractEntities, mapAndSortContracts);

export const getActiveContracts = createSelector(getContracts, (contracts): ContractModel[] => {
  const today = format(new Date(), 'yyyy-MM-dd');
  const filterFn = contractPeriodFilter(today, today);

  return contracts.filter(filterFn);
});

export const getContract = (id: string) => createSelector(getContractEntities, (entities) => mapEntity(id, entities));

export const getPeriodContracts = (date) =>
  createSelector(getContracts, (contracts) => {
    const filterFn = contractPeriodFilter(date, date);

    return contracts.filter(filterFn);
  });

export const canViewContracts = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermissionForAllDepartments(
      {
        permissions: 'View contracts',
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);
